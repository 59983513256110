import React from "react";

const Home = () => {
  return (
    <div>
      <h1>Prashant Chaudhary</h1>
      <p>I am an engineer.</p>
    </div>
  );
};

export default Home;
